import React from 'react'
import { MapPin, Smartphone, Mail } from 'react-feather'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import FormSimpleAjax from '../components/FormSimpleAjax'
import Content from '../components/Content'
import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import './ContactPage.css'
import Iframe from '../components/IFrame'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({
  body,
  title,
  subtitle,
  featuredImage,
  address,
  phone,
  email,
  locations
}) => (
    <main className="Contact">
      <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
      <section className="section Contact--Section1">
        <div className="container Contact--Section1--Container">
          <div>
            <Content source={body} />
            <div className="Contact--Details">
              {address && (
                <a
                  className="Contact--Details--Item"
                  href={"https://goo.gl/maps/YQxjUFVRcRENxt9J8"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {address}
                </a>
              )}
              {phone && (
                <a className="Contact--Details--Item" href={`tel:${phone}`}>
                  <Smartphone /> {phone}
                </a>
              )}
              {email && (
                <a className="Contact--Details--Item" href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              )}
              <hr/>
              <div>
                <table class="table is-striped">
                  <thead>
                    <tr>
                      <th>Timings</th>
                      <th>From</th>
                      <th>To</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Monday</th>
                      <th>4pm</th>
                      <th>9pm</th>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Tuesday</th>
                      <th>4pm</th>
                      <th>9pm</th>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Wednesday</th>
                      <th>4pm</th>
                      <th>9pm</th>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Thursday</th>
                      <th>4pm</th>
                      <th>9pm</th>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Friday</th>
                      <th>4pm</th>
                      <th>9pm</th>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Saturday</th>
                      <th>10:30am</th>
                      <th>9pm</th>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Sunday</th>
                      <th colSpan={2}>On Appointment</th>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
              </div>

              <div>
                <Iframe 
                  url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9348.466634860615!2d77.22642275779488!3d28.57404756930425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9ec39b1694ac3a0d!2sDr.%20Poonam%20Dutt%2C%20Synergy%20Dental%20Clinic!5e0!3m2!1sen!2sus!4v1581663381886!5m2!1sen!2sus"
                />
              </div>
            </div>
    </section>

    
  </main>
        )
        
const ContactPage = ({data: {page} }) => (
  <Layout
          meta={page.frontmatter.meta || false}
          title={page.frontmatter.title || false}
        >
          <ContactPageTemplate {...page.frontmatter} body={page.html} />
        </Layout>
        )
        
        export default ContactPage
        
        export const pageQuery = graphql`
  query ContactPage($id: String!) {
          page: markdownRemark(id: {eq: $id }) {
          ...Meta
      html
        frontmatter {
          title
        template
        subtitle
        featuredImage
        address
        phone
        email
        locations {
          mapLink
          lat
        lng
      }
    }
  }
}
`
