import React from 'react';

export default function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<iframe height="500px" style="width: 100%; border: 0px; height: 500px !important;" title="" src="${props.url}"></iframe>`
      }}
    />
  );
}
